export default class DateHelper {
    
    IsWeekday(year, month, day) 
    {
        var day = new Date(year, month, day).getDay();
        return day !=0 && day !=6;
    }
    
    GetISOYearNumber(selectedDate)
    {
        const date = new Date(selectedDate.getTime());
        const dayNumber = (date.getUTCDay() + 6) % 7;
        date.setUTCDate(date.getUTCDate() - dayNumber + 3);
        return date.getUTCFullYear();
    }

    GetISOWeekNumber(selectedDate) {
        const date = new Date(selectedDate.getTime()); // Create a copy
        const dayNumber = (date.getUTCDay() + 6) % 7;
        date.setUTCDate(date.getUTCDate() - dayNumber + 3);
        const firstThursday = new Date(Date.UTC(date.getUTCFullYear(), 0, 4));
        const weekNumber = Math.ceil(((date - firstThursday) / 86400000 + 1) / 7);
        return weekNumber;
    }

    GetWeekdaysInMonth(month, year) 
    {
        var days = getDaysInMonth(month, year);
        var weekdays = 0;

        for(var i=0; i < days; i++) {
            if (IsWeekday(year, month, i+1)) weekdays++;
        }
        return weekdays;
    }

    GetMonthNameArray() {
        const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
        return months;
    }

    CreateYearDropDownData() {
        const today = new Date();
        
        const options = [
            {
                text: today.getFullYear().toString(),
                value: today.getFullYear()
            },
            {
                text: (today.getFullYear() + 1).toString(),
                value: today.getFullYear() + 1
            }
        ]
    
        return options;
    }

    CreateMonthDropDownData() {
    
        const options = [
            {
                text: "January",
                value: 1
            },
            {
                text: "February",
                value: 2
            },
            {
                text: "March",
                value: 3
            },
            {
                text: "April",
                value: 4
            },
            {
                text: "May",
                value: 5
            },
            {
                text: "June",
                value: 6
            },
            {
                text: "July",
                value: 7
            },
            {
                text: "August",
                value: 8
            },
            {
                text: "September",
                value: 9
            },
            {
                text: "October",
                value: 10
            },
            {
                text: "November",
                value: 11
            },
            {
                text: "December",
                value: 12
            }
        ]
    
        return options;
    }

    GetDaysInMonth(month, year)
    {
        return 32 - new Date(year, month, 32).getDate();
    }
}